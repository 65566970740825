import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Backdrop, Button, CircularProgress, Input, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Box, Flex, Typography } from "venwiz-ui";
import { useEffect, useState } from "react";
import { colors } from "Theme";
import { useDispatch } from "react-redux";
import { JobRequestActions } from "container/JobRequests/actions";
import { FORM_DATA_KEY } from "../../jobRequest.constants";
import { useHistory } from "react-router-dom";
import { useStyles } from "components/VendorCapabilitiesUpdate/constant";
import AntdToast from "components/Toast/AntdToast";

const UnstructuredComparison = () => {
  const [templateFile, setTemplateFile] = useState<any>()
  const [filledFiles, setFilledFiles] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [quotesTable, setQuotesTable] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const submitTemplateFile = (e: any) => {
    setTemplateFile(e.target.files[0])
  }

  const submitFilledVendorQuotes = (e: any) => {
    setFilledFiles(e.target.files)
  }
  useEffect(() => {
    dispatch(JobRequestActions.getComparisonFile()
    ).then((data: any) => {
      setQuotesTable(data)
    });
  }, [])

  const submitTemplateForComparison = () => {
    const formData = new FormData();
    ([templateFile]).concat(...filledFiles).forEach(file => {
      formData.append(FORM_DATA_KEY, file);
    })
    // setIsLoading(true)
    AntdToast({ type: "success", message: "Success", description: "Comparison is being generated. Please come back in 5 minutes" });
    dispatch(JobRequestActions.postFilesForUnstructuredComparison(formData)
    ).then((data: any) => {
      // setIsLoading(false)
      console.log(data)
    });
  }

  return (<>
    <Backdrop className={classes.backdrop} open={isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
    <Box>
      <Flex margin={"30px"}>
        <Button
          variant="contained"
          component="label"
          className="upload-button ml-3"
          startIcon={<FileUploadIcon />}
          style={{ width: '250px', background: '#47c882', color: '#ffffff' }}
        >
          Upload Template File
          <input
            type="file"
            id="template-xlsx"
            hidden={true}
            accept=".xlsx"
            onInput={(e) => submitTemplateFile(e)}
          />
        </Button>
        <Typography>
          <p style={{ marginLeft: "30px" }}>{templateFile?.name}</p>
        </Typography>
      </Flex>
      {!!templateFile &&
        <>
          <Button
            variant="contained"
            component="label"
            className="upload-button ml-3"
            startIcon={<FileUploadIcon />}
            style={{ width: '350px', background: '#47c882', color: '#ffffff' }}
          >
            Upload Filled Vendor Quotes
            <input
              type="file"
              multiple={true}
              id="filled-templates"
              hidden={true}
              onInput={(e) => submitFilledVendorQuotes(e)}
            />
          </Button>
          <Typography>
            {filledFiles && filledFiles.length
              && Object.values(filledFiles)?.map((file: any) => (
                <p style={{ marginLeft: "30px" }}>{file?.name}</p>
              ))
            }
          </Typography>
        </>
      }
      {!!filledFiles && filledFiles.length
        &&
        <Button variant="contained"
          component="label"
          className="upload-button ml-3"
          onClick={() => submitTemplateForComparison()}
          style={{ width: '350px', background: colors.primary, color: '#ffffff' }}>
          Submit and create comparison
        </Button>
      }
      {false && <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>LHS files</TableCell>
            <TableCell align="left">Vendor Quote files</TableCell>
            <TableCell align="left">Comparison file </TableCell>
            <TableCell align="left">Status </TableCell>

          </TableRow>
        </TableHead>
        < TableBody >

          {quotesTable?.length > 0 &&
            quotesTable.map((row: any) => (
              <TableRow key={row?._id}>
                <TableCell>
                  <a target="_blank" rel="noopener noreferrer" href={row?.boq?.url}>
                    {row?.boq?.name}
                  </a>
                </TableCell>
                <TableCell>
                  {row?.vendor_quotes_name?.length > 0 &&
                    row?.vendor_quotes_name?.map((vendor: any) => (
                      <> <a target="_blank" rel="noopener noreferrer" href={vendor?.url}>
                        {vendor?.name}
                      </a><br /> </>
                    ))
                  }
                </TableCell>
                <TableCell>
                  <a target="_blank" rel="noopener noreferrer" href={row?.result}>
                    {row?.result}
                  </a>
                </TableCell>
                <TableCell>
                  {row?.status}
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>

      }

    </Box>


  </>)
}

export default UnstructuredComparison;
