
import QuotationTemplate from "container/JobRequests/JobRequestDetails/Quotations/QuotationTemplate/QuotationTemplate";
import QuotationComparisonTool from "container/JobRequests/JobRequestDetails/Quotations/QuoteComparisonTool/QuoteComparisonTool";
import UnstructuredComparison from "container/JobRequests/JobRequestDetails/Quotations/UnstructuredComparison/UnstructuredComparison";

export default function Comparison() {
  return <>
    <QuotationComparisonTool></QuotationComparisonTool>
    {/* <QuotationTemplate></QuotationTemplate>*/}
    <h3>Unstructured quote Comparison</h3>
    <UnstructuredComparison></UnstructuredComparison>

  </>
}
