import {Fragment} from "react";
import {Redirect} from "react-router-dom";
import UpdateClientCompanyInfo from "../components/ClientCompanyInfoUpdate";
import UpdateClientInfo from "../components/ClientInfoUpdate";
import UpdateClientPlantInfo from "../components/ClientPlantInfoUpdate";
import UserInvites from "../components/UserInvites/UserInvites";
import UpdateCapabilities from "../components/VendorCapabilitiesUpdate";
import UpdateCertificates from "../components/VendorCertificatesUpdate";
import UpdateDocuments from "../components/VendorDocumentsUpdate";
import UpdateExperiences from "../components/VendorExperienceUpdate";
import UpdateCompanyInfo from "../components/VendorsCompanyInfoUpdate";
import UpdatePeople from "../components/VendorUpdatePeopleInfo";
import UpdateSow from "../components/VendorUpdateScopeOfWork";
import UpdateVerification from "../components/VendorVerificationUpdate";
import UpdateTags from "../components/VendorTagsUpdate";
import UpdateUserInfo from "../components/VendorUserInfoUpdate";
import {VendorReviews} from "../container/UpdateVendorProfiles/index";
import ChatModule from "../container/ChatModule";
import ClientAnalyticsDashboard from "../container/analytics/client/User/Dashboad/Dashboard";
import AggregateDataContainer from "../container/analytics/client/aggregate/AggregateDataContainer/AggregateDataContainer";
import VendorAggregateDataContainer from "../container/analytics/vendor/aggregate/VendorAggregateDataContainer/VendorAggregateDataContainer";
import ClientProfiles from "../container/ClientProfiles/ClientProfiles";
import ClientVendorCommunication from "../container/ClientVendorCommunication/ClientVendorList";
import ClientVendorMessagingActivity from "../container/ClientVendorCommunication/ClientVendorMessagingActivity";
import CommunicatedClientVendor from "../container/ClientVendorCommunication/CommunicatedClientvendor";
import JobRequestsListing from "../container/JobRequests/JobRequests";
import DisplayTable from "../container/JobRequests/JobRequestDetails/DisplayTable/DisplayTable";
import {JobDocs} from "../container/JobRequests/JobDocs/JobDocs";
import {JobRequestDetails} from "../container/JobRequests/JobRequestDetails/JobRequestDetails";
import {ComparedQuotationPage} from "../container/JobRequests/JobRequestDetails/Quotations/ComparedQuotation/Pages/ComparedQuotation.page";
import UpdateClientProfilesWrapper from "../container/UpdateClientProfilesWrapper/UpdateClientProfilesWrapper";
import UpdateVendorProfile from "../container/UpdateVendorProfiles/UpdateVendorProfiles";
import VendorProfiles from "../container/VendorProfiles";
import PrivateRoute from "./PrivateRoutes";
import Tags from "../container/tags";
import VendorCreation from "../container/VendorCreation";
import ClientRequests from "container/ClientRequests/ClientRequests";
import {ClientProjectDetails} from "container/ClientRequests/ClientProjects/ClientProjectDetails/ClientProjectDetails";
import VendorPublish from "container/VendorPublish";
import VendorRefreshTOS from "container/VendorRefreshTOS";
import BulkVendorDelete from "container/VendorDeletion";
import Settings from "../components/Settings/Settings"
import Comparison from "../components/Comparison/Comparison"
import Automation from "../components/Comparison/Automation"
const WrappedRoutes=() => (
  <Fragment>
    <PrivateRoute exact path="/">
      <Redirect to="/vendors" />
    </PrivateRoute>
    <PrivateRoute exact path="/vendors" component={VendorProfiles} />
    <PrivateRoute exact path="/job-requests" component={JobRequestsListing} />
    <PrivateRoute
      exact
      path="/job-requests/:_id"
      component={JobRequestDetails}
    />
    <PrivateRoute exact path="/job-requests/:jobId/quote-comparison/:quoteType" component={ComparedQuotationPage} />
    <PrivateRoute
      exact
      path="/job-requests/:_id/:tabIndex"
      component={JobRequestDetails}
    />
    <PrivateRoute
      exact
      path="/job-requests/:jobId/quote-comparison/:quoteType"
      component={ComparedQuotationPage}
    />
      <PrivateRoute exact path="/messaging" component={ChatModule} />
    {/* Vendor Profile */}
    <PrivateRoute
      exact
      path="/updateVendor/:gstn"
      component={UpdateVendorProfile}
    />
    <PrivateRoute
      exact
      path="/updateVendor/:clientId"
      component={UpdateClientProfilesWrapper}
    />
    <PrivateRoute exact path="/updateSow/:gstn" component={UpdateSow} />
    <PrivateRoute exact path="/updatePeopleinfo/:gstn" component={UpdatePeople} />
    <PrivateRoute exact path="/updateCapabilties/:gstn" component={UpdateCapabilities} />
    <PrivateRoute exact path="/updateExperiences/:gstn" component={UpdateExperiences} />
    <PrivateRoute exact path="/updateCompanyInfo/:gstn" component={UpdateCompanyInfo} />
    <PrivateRoute exact path="/updateCertificates/:gstn" component={UpdateCertificates} />
    <PrivateRoute exact path="/updateDocuments/:gstn" component={UpdateDocuments} />
    <PrivateRoute exact path="/updateVerification/:gstn" component={UpdateVerification} />
    <PrivateRoute exact path="/updateTags/:gstn" component={UpdateTags} />
    <PrivateRoute exact path="/updateUserInfo/:gstn" component={UpdateUserInfo} />
    <PrivateRoute exact path="/updateReviews/:gstn" component={VendorReviews} />

    {/* Client Profile */}
    <PrivateRoute exact path="/client-info/:clientId" component={UpdateClientInfo} />
    <PrivateRoute exact path="/client-company/:clientId" component={UpdateClientCompanyInfo} />
    <PrivateRoute exact path="/client-plantInfo/:clientId" component={UpdateClientPlantInfo} />
    <PrivateRoute exact path="/analytics-clients/list" component={AggregateDataContainer} />

    <PrivateRoute exact path="/analytics-vendor/list" component={VendorAggregateDataContainer} />
    <PrivateRoute exact path="/analytics-clients/:clientId/dashboard" component={ClientAnalyticsDashboard} />
    <PrivateRoute exact path="/user-invites" component={UserInvites} />
    <PrivateRoute exact path="/clients" component={ClientProfiles} />
    <PrivateRoute exact path="/client-vendor-messages" component={ClientVendorCommunication} />
    <PrivateRoute exact path="/client-vendor-messages/:userType/:id" component={CommunicatedClientVendor} />
    <PrivateRoute
      exact
      path="/client-vendor-messages/:clientId/:vendorId/messages"
      component={ClientVendorMessagingActivity}
    />
    <PrivateRoute exact path="/userInvites" component={UserInvites} />
    <PrivateRoute exact path="/vendorTags" component={Tags} />
    <PrivateRoute exact path="/settings" component={Settings} />
    <PrivateRoute exact path="/comparison" component={Comparison} />
    <PrivateRoute exact path="/automation" component={Automation} />

    <PrivateRoute exact path="/bulk-vendor-creation" component={VendorCreation} />
    <PrivateRoute exact path="/bulk-vendor-publish" component={VendorPublish} />
    <PrivateRoute exact path="/refresh-tos" component={VendorRefreshTOS} />
    <PrivateRoute exact path="/bulk-vendor-deletion" component={BulkVendorDelete} />
    {/* client request */}
    <PrivateRoute exact path="/client-requests" component={ClientRequests} />
    <PrivateRoute exact path="/client-projects" component={ClientRequests} />
    <PrivateRoute exact path="/client-vendor-requests" component={ClientRequests} />
    <PrivateRoute exact path="/client-project/:projectId" component={ClientProjectDetails} />
    <PrivateRoute exact path="/job-docs" component={JobDocs} />
  </Fragment>
);

export default WrappedRoutes;
