import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import "../JobRequests.scss";
import moment from "moment";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from "@material-ui/icons/Publish";
import ROLES from "../../../constants/RolesConstants";
import {
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Chip,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
} from "@material-ui/core";
import LocalStorage from "../../../_services/LocalStorage";
import { formatTime, getDateFromUTC } from "Utils/DisplayTimeFormat";
import { getLoggedInUsers } from "../../../Utils/Storage";
import CardsShuffler from "../../../components/CardsShuffler/CardsShuffler";
import { getJobTitleFromDescp, getSharableJobLink } from "../jobRequest.helper";
import { useDispatch, useSelector } from "react-redux";
import { JobRequestActions } from "../actions";
import { JOB_REQUEST_DETAILS_ROW_CONFIG } from "../Constants/constants";
import { DropdownSelect } from "./JobStatusSelect";
import { ClientRequestActions } from "container/ClientRequests/actions";
import { Autocomplete } from "@mui/material";
import {  checkHasAnyOfTheRole } from "shared/helpers/checkHasAccess";
import { useCheckVendorRemovedByClient } from "../hooks/useCheckVendorRemovedByClient";
import { mapSource } from "Utils/common";
import { AddRelevantVendors } from "../Interfaces/JobRequestInterfaces";
import { DropDown } from "components/VendorCapabilitiesUpdate/components/DropDown";

export const RenderJobReqCellValue = (props: any) => {
  const [gstnOrEmailDropDownValue, setGstnOrEmailDropDownValue] = useState("gstn");
  const [emailOrGstnText, setEmailOrGstnText] = useState("");
  const dispatch = useDispatch();

  /** All user are considered as Admin User now */
  const user = getLoggedInUsers() ? getLoggedInUsers() : "";
  const adminUser = user;
  const toShowAddButton = checkHasAnyOfTheRole([ROLES.CLIENT_PROJECTS, ROLES.CLIENT_VR_EDIT_PUBLISH]);
  const keyName = props.jobRequestProperty;
  const value = props.jobRequestData[keyName];
  const { projectNames } = useSelector((state: any) => state.ClientRequestReducer);
  const { checkVendorRemovedByClient } = useCheckVendorRemovedByClient();

    useEffect(() => {
      if(keyName === JOB_REQUEST_DETAILS_ROW_CONFIG.ADD_TO_PROJECT.key){
        dispatch(ClientRequestActions.getCompanyProjectNames(props?.companyId));
      }
    },[props.jobRequestData])

    const getValues = (s: any) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const gstinRegex =
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z1-9]{1}[Z]{1}[A-Z0-9]{1}$/;

      // Extract sendTo values that are GSTINs and not emails
      const gstinArray = s
        .map((item: { sendTo: any }) => item.sendTo)
        .filter(
          (sendTo: string) =>
            gstinRegex.test(sendTo) && !emailRegex.test(sendTo)
        );

      return gstinArray;
    };

  const [gstValue , setGstValue] =useState("")
  const disableAddToNewButton = () => {
    let isButtonDisabled = true;
    if (gstnOrEmailDropDownValue === "gstn") {
      if (emailOrGstnText.length >= 15) {
        isButtonDisabled = false;
      }
    } else if (gstnOrEmailDropDownValue === "email") {
      const EMAIL_REGEX = /\S+@\S+\.\S+/;
      if (EMAIL_REGEX.test(emailOrGstnText) && emailOrGstnText.length >= 4) {
        isButtonDisabled = false;
      }
    }
    return isButtonDisabled;
  };

  const addGstnToRequest = (id: any, rank: any, gstn: any, suggested: any) => {
    let payload = {
      rank: rank + 1,
      gstn: gstn,
      isNewlyAdded: true,
      id: gstn,
    };
    let suggestionArray = [];
    suggestionArray.push(payload);

    let checkIfexists = suggested?.filter((suggestedObj: any) => suggestedObj.gstn === gstn) || 0;
    if (checkIfexists.length) {
      props.setShowSnackBar(true);
      props.setErrorMsg("Duplicates are not Allowed !!!");
    } else {
      suggested?.length ? suggested.push(payload) : (suggested = suggestionArray);

      props.setShowSnackBar(false);
      dispatch(
        ClientRequestActions.setJobRequestData({
          key: "suggestedGstns",
          value: suggested,
        }),
      );
      const gstnVal = {
        _id: "",
        gstnValue: "",
      };
      props.setGstnObj(gstnVal);
    }
  };

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const returnDate = (value: any) => {
    return moment(value).format("YYYY-MM-DD");
  };

  const onDelete = (id: any, suggested: any) => {
    let filteredData = suggested.filter((itr: any) => itr.id !== id);
    dispatch(
      ClientRequestActions.setJobRequestData({
        key: "suggestedGstns",
        value: filteredData,
      }),
    );
  };

  const onDragEnd = (result: any, list: any) => {
    if (!result.destination) {
      return;
    }
    const reorderList = reorder(list, result.source.index, result.destination.index);
    dispatch(
      ClientRequestActions.setJobRequestData({
        key: "suggestedGstns",
        value: reorderList,
      }),
    );
  };

  const addGstnToSuggestVendors = async (id: any, payload: any, data: any) => {
    try {
      props.setShowLoader(true);
      const result = await axios.put(
        process.env.REACT_APP_BASE_URL + "/batch-requests/rank/" + id,
        { ...data, ...payload },
        {
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`,
          },
        },
      );
      if (result.data.status === "SUCCESS") {
        props.setReloadUpdate((prev: any) => !prev);
      } else {
        props.setReloadUpdate((prev: any) => !prev);
      }
    } catch (error) {
      props.setReloadUpdate((prev: any) => !prev);
    }
  };

  const updateGstnToRequest = (jobRequestData: any, id: any, rank: any, gstns: any) => {
    if (rank === 0) {
      props.setShowSnackBar(true);
      props.setErrorMsg("Please Enter atleast one GSTN !!!");
    } else {
      gstns.forEach((itr: any) => delete itr.id);
      let payload = {
        ranks: gstns,
        updatedBy: adminUser.email,
      };
      addGstnToSuggestVendors(id, payload, jobRequestData);
    }
  };

  const handleDeleteGstnOrEmail = async (gstnOrEmailObj: any, jobRequestData: any) => {
    props.setShowLoader(true);
    try {
      const { status, data } = await axios.delete(
        process.env.REACT_APP_API_URL + `/v1/request-vendor/remove-vendor/${jobRequestData?._id}/${gstnOrEmailObj?.sendTo}`,
        {
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`,
          },
        }
      );
      if (status === 200) {
        props.setReloadUpdate((prev: any) => !prev);
      }
    } catch (error) {
      props.setErrorMsg(error);
    }
    props.setReloadUpdate((prev: any) => !prev);
  };

  const handleAddNewGstnOrEmail = async (gstnOrEmailDropDownValue: any, payload: any) => {
    try {
      props.setShowLoader(true);
      switch (gstnOrEmailDropDownValue.toLowerCase()) {
        case "gstn":
          //to be updated for multiple gstns 
          // const { status, data } = await axios.get(
          //   process.env.REACT_APP_BASE_URL + `/batch-requests/verify-gstn/${emailOrGstnText}`,
          //   {
          //     headers: {
          //       authorization: `Bearer ${LocalStorage.get("token")}`,
          //     },
          //   },
          // );
          // if (data?.status === "SUCCESS") {
            const gstnResp = await axios.post(
              process.env.REACT_APP_API_URL + `/v1/request-vendor/add-relevant-vendors`,
              payload,
              {
                headers: {
                  authorization: `Bearer ${LocalStorage.get("token")}`,
                },
              },
            );
            if (gstnResp.status === 200) {
              if (gstnResp?.data?.sucess === false) {
                props.setShowSnackBar(true);
                props.setErrorMsg(gstnResp?.data?.message);
              } else {
                setEmailOrGstnText("");
              }
              // props.setReloadUpdate((prev: any) => !prev);
            }
          // } else {
          //   props.setShowSnackBar(true);
          //   props.setErrorMsg(data?.message);
          // }
          break;

        case "email":
          const emailResp = await axios.post(
            process.env.REACT_APP_API_URL + `/v1/request-vendor/add-vendor/${props.jobRequestData._id}/${emailOrGstnText}`,
            {},
            {
              headers: {
                authorization: `Bearer ${LocalStorage.get("token")}`,
              },
            },
          );
          if (emailResp.status === 200) {
            if (emailResp?.data?.sucess === false) {
              props.setShowSnackBar(true);
              props.setErrorMsg(emailResp?.data?.message);
            } else {
              setEmailOrGstnText("");
            }
            props.setReloadUpdate((prev: any) => !prev);
          }
          break;

        default:
          break;
      }
    } catch (error) {
      props.setErrorMsg(error);
    }
    props.setReloadUpdate((prev: any) => !prev);
  };

  const handleSendNewEmails = async (jobRequestData: any) => {
    props.setShowLoader(true);
    try {
      const getVendorsArr = (jobRequestData: any) => {
        let vendorsArr: any = [];
        jobRequestData?.sentToNew?.forEach((gstnOrEmailObj: any) => {
          if (!gstnOrEmailObj?.sendStatus) {
            vendorsArr.push(gstnOrEmailObj.sendTo);
          }
        });
        return vendorsArr;
      };

      const payload = {
        id: jobRequestData?._id,
        jobRequestId: jobRequestData?.requestId,
        clientId: jobRequestData?.clientId,
        vendors: getVendorsArr(jobRequestData),
        shareableLink: getSharableJobLink(jobRequestData?.requestId),
        request_type_name: "job_request",
        sendDocs: true,
      };
      const { status, data } = await axios.post(
        process.env.REACT_APP_BASE_URL + "/batch-requests/send-email-vendor",
        payload,
        {
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`,
          },
        },
      );
      if (status === 200) {
        props.setReloadUpdate((prev: any) => !prev);
      }
      props.setShowLoader(false);
    } catch (error) {
      props.setShowLoader(false);
      props.setErrorMsg(error);
    }
    props.setReloadUpdate((prev: any) => !prev);
  };

  const ClientHasNotAskedForSuggestions = () => {
    return <>Client has not requested Venwiz to suggest Vendors.</>;
  };

  const isUniqueEmailOrGSTN = (emailOrGstnText: any) => {
    const isExistingInSuggestedGstnRanks = props?.jobRequestData?.suggestedGstns?.find(
      (vendorObj: any) => vendorObj?.gstn === emailOrGstnText,
    );
    const isExistingInVendorAddedByClients = props?.jobRequestData?.vendorAddedByClient?.find(
      (vendorObj: any) => vendorObj?.email === emailOrGstnText || vendorObj?.gstn === emailOrGstnText,
    );

    const isExistingInVendorRemovedByClient = checkVendorRemovedByClient(emailOrGstnText);

    // validate duplicates
    if (isExistingInSuggestedGstnRanks || isExistingInVendorAddedByClients || isExistingInVendorRemovedByClient) {
      props.setShowSnackBar(true);
      isExistingInSuggestedGstnRanks && props.setErrorMsg("GSTN already present in Suggested GSTNs");
      isExistingInVendorAddedByClients && props.setErrorMsg("Vendor Email/GSTN - Already Added by Client");
      isExistingInVendorRemovedByClient && props.setErrorMsg("Vendor Email/GSTN - Removed from the job by Client");
      return false;
    }
    return true;
  };

  const addToProject = (project: any) => {
    if(project?.value) {
      const payload = {
        projectId: project.value,
        vendorRequestId: props.jobRequestData._id
      }
       dispatch(ClientRequestActions.addVRToProject(payload));
    }
  }


  switch (keyName) {
    case "clientName":
    case "budget":
    case "clientEmail":
    case "updatedBy":
    case "description":
    case "requestId":
    case "updatedBy":
    case "requiredCertifications":
    case "searchKeyWord":
      return <>{value || "-"}</>;

    case "estimatedPOIssueDate":
      return <>{value ? returnDate(value) : "-"}</>;

    case "requestedOn":
      return <>{value ? formatTime(value, "YYYY-MM-DD HH:mm:ss") : "-"}</>;

    case "updatedOn":
    case "source":
      return <>{value? mapSource(value): "-"}</>;
    case "lastSaved":
    case "adminEditedRequestOn":
      return <>{value ? getDateFromUTC(value) : "-"}</>;

    case "jobLocation":
      return <>{`${value?.city} ${value?.city? ",":''} ${value?.state}`}</>;

    case "natureOfWork":
      return <>{value?.join("; ")}</>;

    case "documentFile":
      return (
        <>
          {value?.map((itr: any, key: any) => {
            return (
              <div className="mt-2">
                <a href={itr?.file?.fileUrl} target="_blank" key={key}>
                  <GetAppIcon />
                  {itr?.file?.fileName}
                </a>
              </div>
            );
          })}
        </>
      );

    case "status":
      return (
        <span
          className={
            value === "Published"
              ? "status_published"
              : value == "InProgress"
              ? "status_in_progress"
              : value == "Pending"
              ? "status_pending"
              : "status_modified"
          }
        >
          {value?.toUpperCase()}
        </span>
      );

    case "vendor":
      const vendors = value?.filter((companyName: string) => !checkVendorRemovedByClient(companyName)) || [];
      /** Suggested Vendor & Rank */
      return (
        <>
          {vendors?.length > 0 &&
            vendors?.map((itr: any, key: any) => (
              <div className="vendor_name">
                <div key={key} className="rank_circle">
                  {key + 1}
                </div>
                {itr}
              </div>
            ))}
        </>
      );

    case "suggestedGstns":
      /** Suggested Vendor GSTN */
      if (!props?.jobRequestData?.suggestVendorByAdmin) {
        return <ClientHasNotAskedForSuggestions />;
      }

      const handleAddGstnToRequest = () => {
        if (props.gstnObj?.gstnValue?.length) {
          if (isUniqueEmailOrGSTN(props.gstnObj?.gstnValue)) {
            addGstnToRequest(
              props.jobRequestData?._id,
              props.jobRequestData?.suggestedGstns?.length || 0,
              props.gstnObj.gstnValue,
              props.jobRequestData?.suggestedGstns
            );
          }
        }
      };

      return (
        <div>
          <CardsShuffler
            items={value?.filter((vendor: any) => !checkVendorRemovedByClient(vendor?.gstn || vendor?.email)) || []}
            onDragEnd={(result: any) => onDragEnd(result, value)}
            onDelete={(id: any) => onDelete(id, value)}
            showDeleteButton={toShowAddButton}
          />
          {toShowAddButton && (
            <Fragment>
              <DropDown
              labelName="Select GSTN"
               size={"small"}
               width={310}
               disabled={false}
               initialValue={gstValue}
               options={getValues(props.jobRequestData?.sentToNew)}
               handleOnChange={(e,value) => {
                setGstValue(value)
                let obj = {
                  _id: props.jobRequestData?._id,
                  gstnValue: value.trim(),
                };
                props.setGstnObj(obj);
               }} 
              />
              {/* <TextField
                id="gstn"
                className="md-5"
                value={props.gstnObj._id === props.jobRequestData._id ? props.gstnObj.gstnValue : "" || ""}
                variant="outlined"
                placeholder="Add GSTN and press ENTER..."
                disabled={false}
                style={{
                  width: "272px",
                  height: 10,
                  marginLeft: "6px",
                }}
                onChange={(e) => {
                  let obj = {
                    _id: props.jobRequestData?._id,
                    gstnValue: e.target.value.trim(),
                  };
                  props.setGstnObj(obj);
                }}
                onKeyPress={(e) => {
                  if (e.code === "Enter" && props.gstnObj?.gstnValue?.length) {
                    addGstnToRequest(
                      props.jobRequestData?._id,
                      props.jobRequestData?.suggestedGstns?.length || 0,
                      props.gstnObj.gstnValue,
                      props.jobRequestData?.suggestedGstns
                    );
                  }
                }}
              /> */}
            </Fragment>
          )}
          <div
            style={{
              marginLeft: "0.5rem",
              marginTop: toShowAddButton ? "4rem" : "0.5rem",
            }}
          >
            {toShowAddButton && (
              <Button
                variant="contained"
                className="add_button"
                color="primary"
                startIcon={<AddIcon style={{ fontSize: "14px" }} />}
                onClick={handleAddGstnToRequest}
              >
                Add
              </Button>
            )}
            {toShowAddButton && (
              <Button
                variant="contained"
                className="ml-2 save_gstn_button mr-2"
                color="primary"
                startIcon={<PublishIcon style={{ fontSize: "14px" }} />}
                onClick={() =>
                  updateGstnToRequest(
                    props.jobRequestData,
                    props.jobRequestData?._id,
                    props.jobRequestData?.suggestedGstns?.length || 0,
                    props.jobRequestData?.suggestedGstns
                  )
                }
              >
                Update
              </Button>
            )}
          </div>
        </div>
      );

    // New Cases
    case "sentToNew":
      /** "Send to" */
      if (!props?.jobRequestData?.suggestVendorByAdmin) {
        return <ClientHasNotAskedForSuggestions />;
      }

      const handleOnClickAddNewButton = (gstnOrEmailDropDownValue: any, emailOrGstnText: any) => {
        const newRelevantVendors = emailOrGstnText.split(",");
        const payload:AddRelevantVendors = {gstns: [], emails:[], id: props.jobRequestData._id}
        newRelevantVendors.map((vendor:string) => {
          if(vendor.includes("@")) {
            payload.emails.push(vendor.trim());
          } else {
            payload.gstns.push(vendor.trim());
          }
        })
        // const isExistingInSendToNew =
        //   value?.length && value?.find((gstOrEmailObj: any) => gstOrEmailObj?.sendTo === emailOrGstnText);
        // if (isExistingInSendToNew) {
        //   props.setShowSnackBar(true);
        //   props.setErrorMsg("Duplicate - Email/GSTN");
        // } else if (isUniqueEmailOrGSTN(emailOrGstnText)) {
          handleAddNewGstnOrEmail(gstnOrEmailDropDownValue, payload); // add new gst/email success
        // }
      };

      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: "0.75rem",
          }}
        >
          {value && value?.length > 0 && (
            <>
              {value?.map((gstOrEmailObj: any, index: any) => {
                return (
                  <div
                    key={index}
                    style={{
                      marginBottom: "0.25rem",
                      display: "flex",
                      padding: "8px",
                      border: "1px solid #e1e1e1",
                      borderRadius: "4px",
                      width: "300px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div>{gstOrEmailObj?.sendTo}</div>
                    {!gstOrEmailObj?.sendStatus && toShowAddButton && (
                      <DeleteIcon
                        style={{ height: "20px", width: "auto", cursor: "pointer" }}
                        onClick={() => {
                          handleDeleteGstnOrEmail(gstOrEmailObj, props.jobRequestData);
                        }}
                      />
                    )}
                  </div>
                );
              })}
            </>
          )}
          {toShowAddButton && (
            <>
              <FormControl
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.75rem",
                }}
              >
                <TextField
                  value={emailOrGstnText}
                  onChange={(e) => setEmailOrGstnText(e.target.value)}
                  variant="outlined"
                  label={`Enter GSTN/Email comma separated`}
                  style={{ width: "300px" }}
                />
              </FormControl>
              <Button
                color="primary"
                size="small"
                variant="contained"
                disabled={disableAddToNewButton()}
                onClick={() => handleOnClickAddNewButton(gstnOrEmailDropDownValue, emailOrGstnText)}
              >
                {`Add to New`}
              </Button>
            </>
          )}
        </div>
      );

    case "sendStatus":
      return (
        <>
          {props.jobRequestData?.sentToNew?.length > 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: "0.75rem",
              }}
            >
              <div>
                {props.jobRequestData?.sentToNew?.map((gstOrEmailObj: any, index: any) => {
                  return (
                    <div key={index}>
                      <div
                        style={{
                          marginBottom: "0.25rem",
                        }}
                      >
                        <Button
                          variant="outlined"
                          style={{ minWidth: 300, textTransform: "none" }}
                          endIcon={
                            gstOrEmailObj?.sendStatus ? (
                              <Chip size="small" label="Sent" style={{ marginLeft: "0.5rem" }} />
                            ) : (
                              <Chip size="small" label="Not Sent" style={{ marginLeft: "0.5rem" }} />
                            )
                          }
                        >
                          {gstOrEmailObj?.sendTo}
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
              {toShowAddButton && (
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={() => handleSendNewEmails(props.jobRequestData)}
                  disabled={props?.jobRequestData?.sentToNew?.every(
                    (gstnOrEmailObj: any) => gstnOrEmailObj?.sendStatus === true
                  )}
                >
                  Send To New
                </Button>
              )}
            </div>
          ) : (
            "-"
          )}
        </>
      );

    case "notInterestResponseAdmin":
      return (
        <>
          {props.jobRequestData?.notInterestResponseAdmin?.length > 0
            ? props.jobRequestData?.notInterestResponseAdmin?.map((notInterestedObj: any, index: any) => {
                return (
                  notInterestedObj?.isInterested === "NOT_INTERESTED" && (
                    <div key={index}>
                      <Button
                        style={{ minWidth: 300, textTransform: "none", marginBottom: "0.25rem" }}
                        variant="outlined"
                        disableRipple
                      >
                        {notInterestedObj?.requested_to}
                      </Button>
                    </div>
                  )
                );
              })
            : "-"}
        </>
      );

    case "interestResponseAdmin":
      return (
        <>
          {props.jobRequestData?.interestResponseAdmin?.length > 0
            ? props.jobRequestData?.interestResponseAdmin?.map((interestedObj: any, index: any) => {
                return (
                  interestedObj?.isInterested === "INTERESTED" && (
                    <div key={index}>
                      <Button
                        variant="outlined"
                        disableRipple
                        style={{ minWidth: 300, marginBottom: "0.25rem", textTransform: "none" }}
                      >
                        {interestedObj?.requested_to}
                      </Button>
                    </div>
                  )
                );
              })
            : "-"}
        </>
      );

    case "reasonToDeclineAdmin":
      return (
        <>
          {props.jobRequestData?.notInterestResponseAdmin?.length > 0 ? (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableCell align="left" style={{ fontWeight: 600 }}>
                      Email/GSTN
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: 600 }}>
                      Reason To Decline
                    </TableCell>
                  </TableHead>
                  {props.jobRequestData?.notInterestResponseAdmin?.map((notInterestObj: any, index: any) => {
                    return (
                      <>
                        {notInterestObj?.isInterested === "NOT_INTERESTED" && (
                          <TableRow key={index}>
                            <TableCell
                              style={{
                                marginBottom: "0.25rem",
                              }}
                            >
                              {notInterestObj?.requested_to || notInterestObj?.email}
                            </TableCell>
                            <TableCell>{notInterestObj?.notInterestedReason}</TableCell>
                          </TableRow>
                        )}
                      </>
                    );
                  })}
                </Table>
              </TableContainer>
            </>
          ) : (
            "-"
          )}
        </>
      );

    case "interestedVendorsNamesbyAdmin":
      return (
        <>
          {props.jobRequestData?.interestResponseAdmin?.length > 0 ? (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableCell align="left" style={{ fontWeight: 600 }}>
                      Email/GSTN
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: 600 }}>
                      Vendor Name
                    </TableCell>
                  </TableHead>
                  {props.jobRequestData?.interestResponseAdmin?.map((interestObj: any, index: any) => {
                    return (
                      <>
                        {interestObj?.isInterested === "INTERESTED" && (
                          <TableRow key={index}>
                            <TableCell
                              style={{
                                marginBottom: "0.25rem",
                              }}
                            >
                              {interestObj?.requested_to || interestObj?.email}
                            </TableCell>
                            <TableCell>{interestObj?.vendorName}</TableCell>
                          </TableRow>
                        )}
                      </>
                    );
                  })}
                </Table>
              </TableContainer>
            </>
          ) : (
            "-"
          )}
        </>
      );

    // case "profileViews":
    // case "rfqAdded":
    // case "rfqSentTo":
    // case "quotationsReceived":
    case JOB_REQUEST_DETAILS_ROW_CONFIG.ADD_TO_PROJECT.key:
      return <span style={{color:"#47C882", fontWeight: "bold", fontSize:"16px"}}>{value ? "YES" : "-"}</span>;
    case JOB_REQUEST_DETAILS_ROW_CONFIG.ASSIGN_TO_PROJECT.key:
      // return props.jobRequestData[JOB_REQUEST_DETAILS_ROW_CONFIG.ADD_TO_PROJECT.key] ? (
       return <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={projectNames}
          sx={{ width: 300, border: "none" }}
          renderInput={(params) => <TextField {...params} label="Select Project" />}
          onChange={(e, value) => {
            addToProject(value);
          }}
          disabled={!checkHasAnyOfTheRole([ROLES.CLIENT_VR_EDIT_PUBLISH])}
        />
      // ) : (
      //   <>-</>
      // );
    case "title" :
      return value || getJobTitleFromDescp(props?.jobRequestData?.description);

    default:
      return <>__Value Not Received</>;
  }
};
