import { Backdrop, Button, CircularProgress, FormControl, Input, Stepper, InputLabel, MenuItem, Select, Step, StepContent, StepLabel, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import { Box, Checkbox, Flex, Typography, width } from "venwiz-ui";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { JobRequestActions } from "container/JobRequests/actions";
import { FORM_DATA_KEY } from "../../jobRequest.constants";
import { useStyles } from "components/VendorCapabilitiesUpdate/constant";
import { StyledBox } from "../Quotation.style";
import { colors } from "Theme";

const QuotationComparisonTool = () => {
  const [templateFile, setTemplateFile] = useState<any>()
  const [filledFiles, setFilledFiles] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [sheetsToCompare, setSheetsToCompare] = useState<any>()
  const [selectedSheets, setSelectedSheets] = useState<any>([]);
  const [headers, setHeaders] = useState<any>();
  const [vendorFileName, setVendorFileName] = useState<any>({});
  const [quotesTable, setQuotesTable] = useState([]);


  const stepsForComparison = [
    "Upload BOQ file", "Select the sheets you want to compare", "Select columns that vendor has to fill",
    "Upload Vendor Quotes", "Subcategory and Pivot Table"];
  const submitTemplateFile = (e: any) => {
    setTemplateFile(e.target.files[0])

  }

  const submitVendorFiles = (e: any) => {
    setFilledFiles(e.target.files)
  }

  const handleNext = async () => {
    setIsLoading(true);
    // Assuming API calls are handled with fetch or axios
    switch (activeStep) {
      case 0:
        // Handle file upload API call
        submitTemplateForComparison()
        break;
      case 1:
        const headerInfoArr: any = {}
        Object.keys(selectedSheets).map((sheetName: any) => {
          headerInfoArr[sheetName] = {
            client: sheetsToCompare[sheetName].headerRowValues,
            vendor: [],
            pivot: []
          }
        })
        setHeaders(headerInfoArr)
        break;
      case 3:
        break;
      case 4:
        createComparison()
        break;
      default:
        break;
    }
    setIsLoading(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 2) {
      setSelectedSheets({})
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  };

  const submitTemplateForComparison = () => {
    const formData = new FormData();
    formData.append(FORM_DATA_KEY, templateFile);
    setIsLoading(true)
    dispatch(JobRequestActions.inspectBOQ(formData)
    ).then((data: any) => {
      setSheetsToCompare(data);
      setSelectedSheets({})
      setIsLoading(false)
    });
  }

  const addHeaderToVendorList = (sheetName: string, clientHeader: string) => {
    let update: any = { ...headers };
    if (!update[sheetName].vendor.includes(clientHeader)) {
      update[sheetName].vendor.push(clientHeader)
    } else {
      update[sheetName].vendor.splice(update[sheetName].vendor.indexOf(clientHeader), 1)
    }
    update[sheetName].vendor = [...Array.from(new Set(update[sheetName].vendor))]
    setHeaders(update);
  }

  const addSheetToCategory = (sheetName: string) => {
    let update: any = { ...headers };
    console.log({ update })
    if (!update[sheetName].subcategory) {
      update[sheetName].subcategory = true
    } else {
      update[sheetName].subcategory = !update[sheetName].subcategory;
    }
    setHeaders(update);
  }

  const addColToPivot = (sheetName: string, colName: string) => {
    let update: any = { ...headers };
    console.log({ update })

    if (!update[sheetName].pivot.includes(colName)) {
      update[sheetName].pivot.push(colName)
    } else {
      update[sheetName].pivot.splice(update[sheetName].vendor.indexOf(colName), 1)
    }
    setHeaders(update);
  }


  const stepDisable = () => {
    if (activeStep === 0 && !templateFile) {
      return true;
    }
    else if (activeStep === 1 && !Object.keys(selectedSheets).length) {
      return true;
    }
    else if (activeStep === 2 && !Object.values(headers).map((v: any) => v.vendor)[0].length) {
      return true;
    }
    else if (activeStep === 3) {
      if (!filledFiles?.length) {
        return true;
      } else if (!!filledFiles?.length && Array.from(filledFiles).map((f: any) => !!vendorFileName[f.name]).filter(Boolean).length !== filledFiles?.length) {
        return true;
      }
    }
    return false;
  }
  const createComparison = () => {
    const payloadForComparison: any = {
      templateFile, filledFiles,
      sheets: []
    }
    Object.keys(headers).map((sheetName: any) => {
      payloadForComparison.sheets.push({
        name: sheetName,
        vendorToFill: headers[sheetName].vendor,
        headerRow: sheetsToCompare[sheetName].headerRow,
        vendorFileName: vendorFileName,
        subcategory: headers[sheetName].subcategory,
        pivot: headers[sheetName].pivot
      })
    })
    const formData = new FormData();
    ([templateFile]).concat(...filledFiles).forEach(file => {
      formData.append(FORM_DATA_KEY, file);
    })
    formData.append("sheets", JSON.stringify(payloadForComparison["sheets"]));
    dispatch(JobRequestActions.quoteComparison(formData, { sheets: payloadForComparison["sheets"] })
    ).then((data: any) => {
    })
  }

  const handleVendorNameChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedName = { ...vendorFileName };
    updatedName[filledFiles[index].name] = e.target.value;
    setVendorFileName(updatedName);
  };

  const changeHeaderRow = (e: any, sheetName: string) => {
    const sheetData = JSON.parse(JSON.stringify(sheetsToCompare));
    sheetData[sheetName].headerRow = e.target.value;
    const formData = new FormData();
    formData.append(FORM_DATA_KEY, templateFile);
    formData.append("data", JSON.stringify({
      sheetName,
      headerRow: e.target.value
    }));
    dispatch(JobRequestActions.getColumns(formData)
    ).then((data: any) => {
      sheetData[sheetName].headerRowValues = data[0];
      setSheetsToCompare(sheetData);
    });
    setSheetsToCompare(sheetData);
  }

  useEffect(() => {
    dispatch(JobRequestActions.getComparisonFile()
    ).then((data: any) => {
      // data = data.filter((d: any) => d.vendor_quotes_name.length === 0);
      setQuotesTable(data)
    });
  }, [])

  return (
    <>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <StyledBox>
        {isLoading && <CircularProgress />}
        <Stepper activeStep={activeStep} orientation="vertical" style={{ width: "100%" }} >
          {stepsForComparison.map((label) => (
            <Step key={label} style={{ width: "100%" }}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <Box>
                  {activeStep === 0 && (
                    <Box style={{ width: "100%", padding: "30px" }}>
                      <Button variant="contained" component="label">
                        Upload File
                        <input type="file" hidden onChange={submitTemplateFile} />
                      </Button>
                      {templateFile && <Typography>{templateFile.name}</Typography>}
                    </Box>
                  )}
                  {activeStep === 1 && (
                    <Box>
                      {!!sheetsToCompare && Object.keys(sheetsToCompare)?.map((sheetName, index) => (
                        <Flex key={index} style={{ justifyContent: "space-between", width: "70%" }}>
                          <Box style={{ display: "flex", width: "200px" }}>
                            <Checkbox
                              // checked={selectedOptions.includes(option)}
                              onChange={() => {
                                if (Object.keys(selectedSheets).includes(sheetName)) {
                                  setSelectedSheets({ ...selectedSheets, [sheetName]: undefined });
                                } else {
                                  setSelectedSheets({ ...selectedSheets, [sheetName]: sheetsToCompare[sheetName].headerRow });
                                }
                              }} />
                            <Typography>{sheetName}</Typography>
                          </Box>
                          <Input
                            disabled={!Object.keys(selectedSheets).includes(sheetName)}
                            style={{ padding: "0", margin: "0px 30px", width: "200px" }} value={sheetsToCompare[sheetName].headerRow} onChange={(e: any) => changeHeaderRow(e, sheetName)}></Input>
                        </Flex>
                      ))}

                    </Box>
                  )}
                  {activeStep === 2 && (
                    <Box>
                      {!!headers && Object.keys(headers)?.map((sheetName, index) => (
                        <Flex key={index} style={{ justifyContent: "space-between", width: "100%" }}>
                          <Box >
                            <><Typography style={{ fontSize: "12px" }}>Sheet name: {sheetName}</Typography>
                              {headers[sheetName]?.client?.map((clientHeader: any) => (
                                <Box style={{ display: "flex", padding: "7px", fontSize: "10px" }}>
                                  <Box style={{ display: "flex", width: "95%", lineHeight: "14px", height: "14px" }}>
                                    <Checkbox
                                      checked={headers[sheetName]?.vendor.includes(clientHeader)}
                                      onChange={() => { addHeaderToVendorList(sheetName, clientHeader) }} />
                                    <Typography>{clientHeader}</Typography>
                                  </Box>
                                  {/* <MdDelete style={{ cursor: "pointer" }} color="red" size={20}
                                    onClick={() => onDelete(sheetName, clientHeader)} /> */}
                                </Box>
                              ))}
                            </>
                          </Box>
                        </Flex>
                      ))}

                    </Box>
                  )}
                  {activeStep === 3 && (
                    <Box style={{ width: "100%", padding: "30px" }}>
                      <Button variant="contained" component="label">
                        Upload Vendor Offers
                        <input type="file" multiple={true} hidden onChange={submitVendorFiles} />
                      </Button>
                      {!!filledFiles?.length && <Typography>No. of Vendor Quotes uploaded: {filledFiles?.length}</Typography>
                        &&
                        <Box padding={"10px"}>
                          <Typography padding={"10px"}> Please enter vendor names corresponding to files: </Typography>
                          {Array.from(filledFiles)?.map((file: any, index: number) => (
                            <Flex padding={"10px"}>
                              <Typography>{file.name}</Typography>
                              <Input
                                style={{ padding: "0", margin: "0px 30px", width: "40%" }}
                                onChange={(e: any) => handleVendorNameChange(e, index)}
                                value={file.vendorName}></Input>
                            </Flex>
                          ))}
                        </Box>
                      }
                    </Box>
                  )}
                  {activeStep === 4 && (
                    <Box style={{ width: "100%", padding: "10px" }}>
                      {!!headers && Object.keys(headers)?.map((sheetName, index) => (
                        <Flex key={index} style={{ justifyContent: "space-between", width: "100%" }}>
                          <Box paddingTop={"10px"}>
                            Select those sheets you want sub-category assignment for:
                            <Box style={{ display: "flex", padding: "7px", fontSize: "10px", width: "200px" }}>
                              <Box style={{ display: "flex", lineHeight: "14px", height: "14px", width: "200px" }}>
                                <Checkbox
                                  checked={headers[sheetName]?.subcategory}
                                  onChange={() => addSheetToCategory(sheetName)}
                                />
                                <Typography style={{ fontSize: "12px", width: "200px" }}> {sheetName}</Typography>
                              </Box>
                            </Box>
                            {!!headers[sheetName]?.subcategory && <Box style={{ backgroundColor: colors.lightGray, padding: "10px", }}>
                              Select columns to be used to for pivot:
                              {headers[sheetName]?.vendor.map((col: string) => (
                                <Box style={{ display: "flex", margin: "10px", padding: "5px", width: "200px" }}>
                                  <Checkbox
                                    checked={headers[sheetName]?.pivot?.includes(col)}
                                    onChange={() => addColToPivot(sheetName, col)}
                                  />
                                  <Typography style={{ fontSize: "12px" }}> {col}</Typography>
                                </Box>
                              ))}
                            </Box>
                            }
                          </Box>
                        </Flex>
                      ))}
                    </Box>
                  )}
                </Box>

              </StepContent>
            </Step>
          ))}
        </Stepper>
        <StyledBox style={{ width: "100%" }} >
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={isLoading || activeStep > 4 || stepDisable()}
          >
            {activeStep === 4 ? "Create Comparison" : "Next"}
          </Button>
          {activeStep > 4 &&
            <Typography> Please refresh page to get gsheet link</Typography>}
        </StyledBox>
      </StyledBox>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Comparison Name</TableCell>
            <TableCell align="left">Google sheet link</TableCell>

          </TableRow>
        </TableHead>
        < TableBody >

          {quotesTable?.length > 0 &&
            quotesTable.map((row: any) => (
              <TableRow key={row?._id}>
                <TableCell>
                  <a target="_blank" rel="noopener noreferrer" href={row?.boq?.url}>
                    {row?.boq?.name}
                  </a>
                </TableCell>

                <TableCell>
                  <a target="_blank" rel="noopener noreferrer" href={row?.result}>
                    Link
                  </a>
                </TableCell>

              </TableRow>
            ))
          }
        </TableBody>
      </Table>

    </>
  )
}

export default QuotationComparisonTool;
